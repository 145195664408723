import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/Layout"

import "../css/home.css"

function Section1() {
  const titleElement = (
    <h1 className="h1 text---white" itemProp="title">
      We believe in your right to find the right thing.
      <br />
      Sorry it didn’t work out this time.
    </h1>
  )
  return (
    <div className="section notfound-hero background---black">
      <div className="xl---padding-12---1-col spacing---xxl">
        {titleElement}
      </div>
    </div>
  )
}

function Section2() {
  const svgElement = (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 747 304"
      enableBackground="new 0 0 747 304"
    >
      <style>
        {`
          .svgElement {
            padding-bottom: 6em;
            width: 100%;
          }
          .st0 {
            fill: none;
            stroke: #000000;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }
          .st1 {
            stroke: #000000;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }
          .st2 {
            fill: #ffffff;
            stroke: #000000;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }
          @keyframes animate-svg-stroke-1 {
            0% {
              stroke-dashoffset: 958.6162526693794px;
              stroke-dasharray: 958.6162526693794px;
            }
            100% {
              stroke-dashoffset: 0;
              stroke-dasharray: 958.6162526693794px;
            }
          }
          .svg-404-1 {
            animation: animate-svg-stroke-1 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
              animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
                both;
          }
          @keyframes animate-svg-stroke-2 {
            0% {
              stroke-dashoffset: 1026.5853271484375px;
              stroke-dasharray: 1026.5853271484375px;
            }
            100% {
              stroke-dashoffset: 0;
              stroke-dasharray: 1026.5853271484375px;
            }
          }
          .svg-404-2 {
            animation: animate-svg-stroke-2 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
              animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
                both;
          }
          @keyframes animate-svg-stroke-3 {
            0% {
              stroke-dashoffset: 1099.174560546875px;
              stroke-dasharray: 1099.174560546875px;
            }
            100% {
              stroke-dashoffset: 0;
              stroke-dasharray: 1099.174560546875px;
            }
          }
          .svg-404-3 {
            animation: animate-svg-stroke-3 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
              animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s
                both;
          }
          @keyframes animate-svg-stroke-4 {
            0% {
              stroke-dashoffset: 1099.174560546875px;
              stroke-dasharray: 1099.174560546875px;
            }
            100% {
              stroke-dashoffset: 0;
              stroke-dasharray: 1099.174560546875px;
            }
          }
          .svg-404-4 {
            animation: animate-svg-stroke-4 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
              animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
                both;
          }
          @keyframes animate-svg-stroke-5 {
            0% {
              stroke-dashoffset: 1099.174560546875px;
              stroke-dasharray: 1099.174560546875px;
            }
            100% {
              stroke-dashoffset: 0;
              stroke-dasharray: 1099.174560546875px;
            }
          }
          @keyframes animate-svg-fill-5 {
            0% {
              fill: transparent;
            }
            100% {
              fill: rgb(0, 0, 0);
            }
          }
          .svg-404-5 {
            animation: animate-svg-stroke-5 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
              animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
                1.2000000000000002s both;
          }
          @keyframes animate-svg-stroke-6 {
            0% {
              stroke-dashoffset: 958.6162526693794px;
              stroke-dasharray: 958.6162526693794px;
            }
            100% {
              stroke-dashoffset: 0;
              stroke-dasharray: 958.6162526693794px;
            }
          }
          @keyframes animate-svg-fill-6 {
            0% {
              fill: transparent;
            }
            100% {
              fill: rgb(0, 0, 0);
            }
          }
          .svg-404-6 {
            animation: animate-svg-stroke-6 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
              animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s
                both;
          }
          @keyframes animate-svg-stroke-7 {
            0% {
              stroke-dashoffset: 1026.5853271484375px;
              stroke-dasharray: 1026.5853271484375px;
            }
            100% {
              stroke-dashoffset: 0;
              stroke-dasharray: 1026.5853271484375px;
            }
          }
          @keyframes animate-svg-fill-7 {
            0% {
              fill: transparent;
            }
            100% {
              fill: rgb(255, 255, 255);
            }
          }
          .svg-404-7 {
            animation: animate-svg-stroke-7 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
              animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
                1.4000000000000001s both;
          }
          @keyframes animate-svg-stroke-8 {
            0% {
              stroke-dashoffset: 1099.174560546875px;
              stroke-dasharray: 1099.174560546875px;
            }
            100% {
              stroke-dashoffset: 0;
              stroke-dasharray: 1099.174560546875px;
            }
          }
          @keyframes animate-svg-fill-8 {
            0% {
              fill: transparent;
            }
            100% {
              fill: rgb(0, 0, 0);
            }
          }
          .svg-404-8 {
            animation: animate-svg-stroke-8 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
              animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s
                both;
          }
        `}
      </style>
      <ellipse
        className="st0 svg-404-1"
        cx="373.5"
        cy="152"
        rx="152.5"
        ry="152"
      />
      <path
        className="st0 svg-404-2"
        d="M410.6,173l32.4,53.1L403.5,249l-30-55.5L343.1,249L304,226.1l32-53.1l-62.1,1V129l62.1,1.7l-32.4-54.1
  	L343.1,54l30.4,55.8l30-55.8L443,76.5l-32.4,54.1L473,129V174L410.6,173z"
      />
      <path
        className="st0 svg-404-3"
        d="M198,230.2v-42.6h-33.3v-61.4h-47.3v61.4H50L148,21H96.5L0,185.7v44.5h117.4V283h47.3v-52.8H198z"
      />
      <path
        className="st0 svg-404-4"
        d="M747,230.2v-42.6h-33.3v-61.4h-47.3v61.4H599L697,21h-51.5L549,185.7v44.5h117.4V283h47.3v-52.8H747z"
      />
      <path
        className="st1 svg-404-5"
        d="M198,231.2v-42.6h-33.3v-61.4h-47.3v61.4H50L148,22H96.5L0,186.7v44.5h117.4V284h47.3v-52.8H198z"
      />
      <ellipse
        cx="373.5"
        cy="151.5"
        rx="152.5"
        ry="152"
        className="svg-404-6"
      />
      <path
        className="st2 svg-404-7"
        d="M410.6,173l32.4,53.1L403.5,249l-30-55.5L343.1,249L304,226.1l32-53.1l-62.1,1V129l62.1,1.7l-32.4-54.1
  	L343.1,54l30.4,55.8l30-55.8L443,76.5l-32.4,54.1L473,129V174L410.6,173z"
      />
      <path
        className="st1 svg-404-8"
        d="M747,231.2v-42.6h-33.3v-61.4h-47.3v61.4H599L697,22h-51.5L549,186.7v44.5h117.4V284h47.3v-52.8H747z"
      />
    </svg>
  )

  const subtitleElement = (
    <p className="h3 text---black">
      We believe in your right to personally determine membership in our culture
      of centralized infrastructure. So, we seek, fund, and build products and
      protocols that focus on cryptocurrency and/or are designed to decentralize
      systems -- to ensure that everyone has the option to be in, or out.
    </p>
  )
  return (
    <div className="section xl---padding---12 xl---v-padding---2">
      <div>
        <div className="xl---padding-12---2-col">
          <div className="svgElement">{svgElement}</div>
          {subtitleElement}
        </div>
      </div>
    </div>
  )
}

function NotFound() {
  return (
    <Layout className="legal-page">
      <Helmet>
        <title>404 Page Not Found</title>
      </Helmet>
      <Section1 />
      <Section2 />
    </Layout>
  )
}

export default NotFound
